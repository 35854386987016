import {
  i18n
} from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import IntegerField from '@/shared/fields/integer-field';
import JsonField from '@/shared/fields/json-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
// import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.invoice.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.invoice.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  receiptNum: new IntegerField('receiptNum', label('receiptNum'), {}),
  total: new DecimalField('total', label('total'), {}),
  transactionId: new StringField('transactionId', label('transactionId'), {}),
  paymentMethod: new EnumeratorField('paymentMethod', label('paymentMethod'), [
    { value: 'cash', label: enumeratorLabel('paymentType', 'cash'), i18n: `entities.invoice.enumerators.paymentType.cash` },
    { value: 'visa', label: enumeratorLabel('paymentType', 'visa'), i18n: `entities.invoice.enumerators.paymentType.visa` },
    { value: 'wallet', label: enumeratorLabel('paymentType', 'wallet'), i18n: `entities.invoice.enumerators.paymentType.wallet` },
  ],{}),
  guide: new JsonField('guide', label('guide')),
  client: new JsonField('client', label('client')),
  tripOwner: new JsonField('tripOwner', label('tripOwner')),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class InvoiceModel extends GenericModel {
  static get fields() {
    return fields;
  }
}