//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { InvoicePermissions } from '@/modules/invoice/invoice-permissions';
import i18n from '@/vueI18n';
import moment from 'moment';

import { InvoiceModel } from '@/modules/invoice/invoice-model';
const { fields } = InvoiceModel;

export default {
  name: 'app-invoice-list-table',

  async mounted() {
    this.doFetchFirstPage()
  },

  data() {
    return {
      filter: {},
      orderBy: 'createdAt',
      columns: [
        {
          name: 'receiptNum',
          field: 'receiptNum',
          label: 'entities.invoice.fields.receiptNumber',
          align: 'center',
        },
        {
          name: 'total',
          field: 'total',
          label: 'entities.invoice.fields.total',
          align: 'center',
        },
        {
          name: 'paymentMethod',
          field: 'paymentMethod',
          label: 'entities.invoice.fields.paymentMethod',
          align: 'center',
        },
        {
          name: 'guideName',
          field: 'guideName',
          label: 'entities.invoice.fields.guideName',
          align: 'center',
        },
        {
          name: 'clientName',
          field: 'clientName',
          label: 'entities.invoice.fields.clientName',
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'entities.invoice.fields.invoiceDate',
          align: 'center',
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.action',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows: 'invoice/list/rows',
      loading: 'invoice/list/loading',
      pagination: 'wallet/list/pagination',
      // paginationLayout: 'layout/paginationLayout',

      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
    }),
    fields() {
      return fields;
    },
    language() {
      return i18n.locale;
    },
    tablePagination: {
      get: function() {
        return {
          page: this.pagination.page || 1,
          rowsPerPage: this.pagination.limit || 10,
          sortBy: this.pagination.orderBy || 'createdAt',
          descending: this.pagination.sortBy == 'desc',
          // rowsNumber: xx if getting data from a server
        }
      },

      set: function(value) {
        console.log(value);
      },
    },
    pagesNumber() {
      return Math.ceil(this.rows.length / this.tablePagination.rowsPerPage);
    },
    pageNumber: {
      get: function () {
        return this.page;
      },
      set: function (value) {
        this.$emit('changePage', value);
      },
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'invoice/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = InvoiceModel.presenter(row, fieldName);
      switch (fieldName) {
        case 'total':
          return this.currentLanguageCode == 'ar'
            ? `${val} ريال سعودي ` 
            : `${val} SAR`  
        case 'receiptNum':
          return this.currentLanguageCode == 'ar' ? `${val} #` : `# ${val}`
        default:
          return val
      }
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(val[0].i18n) : 'ـــ';
      // return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = InvoiceModel.presenter(row, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterOwner(row, fieldName, key) {
      const owner = InvoiceModel.presenter(row, fieldName);
      let fieldValue = owner && owner[key] ? owner[key] : null
      let value
      switch (key) {
        case 'name': 
          value = fieldValue && fieldValue[this.currentLanguageCode] ? fieldValue[this.currentLanguageCode] : 'ـــ'
          break
        case 'avatar':
          value = fieldValue
            ? fieldValue
            : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c';
          break
        default:
          value = fieldValue
          break
      }
      return value ? value : 'ـــ';
    },

    async doFetchFirstPage() {
      return this.doFetch({
        orderBy: 'createdAt',
        pagination: {
          sortBy: 'desc',
          limit: 10,
        },
      });
    },
    async doFetchPrevPage() {
      const firstDocument = this.rows[0]
      const pagination = {
        sortBy: 'desc',
        limit: 10,
        doc: firstDocument,
        action: 'prev'
      }
      return this.doFetch({
        filter: { type: this.type },
        pagination
      });
    },
    async doFetchNextPage() {
      const lastDocument = this.rows[this.rows.length - 1]
      const pagination = {
        sortBy: 'desc',
        limit: 10,
        doc: lastDocument,
        action: 'next'
      }
      return this.doFetch({
        filter: { type: this.type },
        pagination
      });
    },

    async goToPreviousPage() {
      this.filter = {
        month: this.month,
        year: this.year,
      };
      const pagination = {
        sortBy: 'desc',
        limit: this.getItemsPerPage,
        action: 'prev',
        page: this.pageNumber,
      };
      await this.doFetchTripsTransaction({
        filter: this.filter,
        orderBy: this.orderBy,
        pagination,
      });
      this.pageNumber -= 1;
      this.finishTripsTransactionsLoading();
    },
    async loadMore() {
      this.filter = {
        month: this.month,
        year: this.year,
      };
      const pagination = {
        sortBy: 'desc',
        // limit: this.getItemsPerPage,
        limit: this.getItemsPerPage,
        action: 'next',
        page: this.pageNumber,
      };
      await this.doFetchTripsTransaction({
        filter: this.filter,
        orderBy: this.orderBy,
        pagination,
      });
      console.log(this.tripsTransactions);
      this.pageNumber += 1;
      this.finishTripsTransactionsLoading();
    },
    async fetchTransactions() {
      this.filter = {
        month: this.month,
        year: this.year,
      };
      const pagination = {
        sortBy: 'desc',
        limit: this.getItemsPerPage,
      };
      await this.doFetchTripsTransaction({
        filter: this.filter,
        orderBy: this.orderBy,
        pagination,
      });
      console.log(this.tripsTransactions);
      this.finishTripsTransactionsLoading();
    },
  },
};
