//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

import InvoiceListTable from '@/modules/invoice/components/invoice-list-table.vue';

export default {
  name: 'app-invoice-list-page',

  components: {
    [InvoiceListTable.name]: InvoiceListTable,
  },
  computed:{
    ...mapGetters({
      loading: 'invoice/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
  },
};
